
.main-steps {

  margin: 20px;


  .step {
    width: 12rem; /* 框的宽度 */
    height: 4rem; /* 框的高度 */
    background-color: #e6e2e2; /* 框的背景色 */
    text-align: center;
    line-height: 4rem; /* 垂直居中 */
    font-size: 12px;
    clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%); /* 生成五边形 */


  }


  .first-step {
    border-radius: 5px 0 0 5px;
    clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%); /* 生成五边形 */
  }

  .last-step {
    border-radius: 0 5px 5px 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 10% 50%); /* 生成五边形 */
  }

  .completed {
    background-color: #90cd8f; /* 框的背景色 */
  }



  .ant-steps .ant-steps-item-title::after {
    content: none;
  }

  .ant-steps {

    .ant-steps-item {

      .ant-steps-item-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

  }


  .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: black;
    border-color: black;
  }

}
















