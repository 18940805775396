.home-hero {

  background-color: #00bf63;

  width: 100%;
  height: 95vh;
  padding-top: 30px;


  .home-pic {
    margin-top: 10vh;
  }
}



.home-intro {
  padding: 30px 250px 80px 250px;
  background: linear-gradient(to right, black, #4d4d4d);

  .home-intro-card {
    margin-top: 30px;
    padding: 20px;
    background: linear-gradient(to right, #181616, #4d4d4d);
    border-radius: 15px;
    .card-title {
      color: #56b48c;
      margin-bottom: 10px;
    }
    .card-description {
      color: #e0e0e0;
      font-size: 14px;
    }
    .step-circle {
      background-color: #56b48c;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;

      .circle-number {
        color: white;
        font-weight: bold;
        margin: 0;
      }
    }
  }


}


.home-self {
  padding: 80px 200px ;
}





.home-quick {
  padding: 50px 200px 80px 200px;
  background: linear-gradient(to right, black, #4d4d4d);

}


.home-expert {
  padding: 30px 200px 80px 200px;
  background-color: #f4f4f4;

}

.home-community {
  padding: 50px 200px 80px 200px;
  background: linear-gradient(to right, black, #4d4d4d);

}


.home-footer {
  padding: 50px 400px 50px 400px;
  background-color: #00bf63;
}


.custom-card {
  transition: transform 0.3s ease;
  margin: 0 auto;  /* 居中对齐 */
}
.custom-card:hover {
  transform: translateY(-10px); /* 卡片上浮效果 */
}

.carousel-card {
  margin-right: 5px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-carousel .slick-slide {
  padding: 10px;
  display: flex;  /* 使用 flexbox 对齐 */
  justify-content: center;  /* 居中对齐 */
  align-items: center;  /* 垂直居中对齐 */
}