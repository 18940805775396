.step-form-container {
  display: flex;
  align-items: flex-start;
  margin: 20px;
  width: 80%;

  .progress-bar {
    width: 250px;
    //margin-right: 40px;

    .ant-steps-item-title {
      font-size: 14px;
      color: gray;
    }

    .ant-steps-item {
      margin-bottom: 40px; /* Adjust the value as needed */
    }

    :where(.css-dev-only-do-not-override-1yfh79i).ant-steps.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
      height: 40px; /* Adjust the height as needed */
      width: 3px;
    }

    .step-completed .ant-steps-item-title {
      color: green;
      font-weight: bold;
    }

    .step-pending .ant-steps-item-title {
      color: lightgray;
    }
  }

  .form-content {
    flex-grow: 0;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 60%;

    .ant-form-item-label > label {
      font-size: 18px; /* 修改全局表单 label 字体大小 */
    }

    .form-navigation {
      margin-top: 20px;

      .ant-btn {
        margin-right: 10px;
      }
    }
  }
}

.eduEmployForm {
  padding: 0 8px;
  width: 100%;

  .edu-history {
    margin-bottom: 20px;
    border: 2px #3db73a dashed;
    padding: 5px;
    border-radius: 10px;

    .edu-history-item {
      padding: 15px 5px 0 5px;
      background-color: #e1eced;
      margin-bottom: 20px;
      border-radius: 10px;
    }
  }
}

.external .ant-tabs-nav-list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.external .ant-tabs-tab {
  font-size: 18px; // 字体大小
  font-weight: bold; // 加粗
  width: 34%; // 平分宽度

}


.plan-item {

  background-color: white;
  border-radius: 5px;

  .plan-item-link {
    margin-top: 10px;
    padding-left: 15px;
    text-decoration: underline;
  }

  .plan-item-title {
    position: relative; // 确保子元素可以绝对定位
    height: 50px;
    line-height: 50px;
    padding-left: 15px;
    background-color: #3db73a;
    border-radius: 5px 5px 0 0;

    .free-badge {
      position: absolute;
      top: 0; // 距离卡片顶部的位置
      right: 80px; // 距离卡片右边的位置
      background-color: orange; // "Free" 图标的背景色
      color: white; // 文字颜色
      font-size: 14px; // 字体大小
      padding: 5px 10px; // 内边距，控制文字周围的空间
      border-radius: 5px; // 使角落变圆，模仿标签样式
      font-weight: bold; // 加粗文字
      //transform: rotate(45deg); // 使标签旋转 -45 度
      //transform-origin: top left; // 旋转的参考点为右上角
      //clip-path: polygon(-30% 0%, 100% 0%, 130% 100%, 0% 100%); // 平行四边形形状
    }
  }


  .plan-item-content {

    padding: 15px;

    .plan-item-feature {
      padding-right: 5px;
      border-right: 1px solid #b5acac; /* 竖线 */
    }

    .imigraBoost-content-button {
      //margin-top: 40px;
      height: 100%;
    }

    .icon-spacing {
      margin-right: 8px;
    }

  }


}






