
.card-with-green-shadow {
  box-shadow: inset 0 4px 8px rgba(144, 238, 144, 0.5); /* 浅绿色阴影 */
  //border-radius: 8px; /* 可选：为卡片添加圆角 */
}

.post-content {
  margin-top: 20px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.post-button {
  button {
    font-weight: bolder;
    color: #5B6060;
    font-size: 0.9rem;
  }
}

.ant-timeline {
  width: 90%;
  .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: 20px;
  }
}

