@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    font-family: 'Nunito', sans-serif;
    background-color: #fff;
}

.container8 {
    max-width: 80%;
    margin: auto;
}

.container6 {
    max-width: 60%;
    margin: auto;
}

.flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

h{
    margin: 0;
}

h1, h2 {
    font-weight: 600;
}

h3 {
    font-weight: 500;
}

h4 {
    color: #8491a5;
    font-size: 14px;
    font-weight: 500;
}

p {
    color: #5a5a5a;
    font-size: 15px;
}

ul {
    list-style: none; /* 移除列表项的默认样式 */
    padding: 0; /* 移除列表的内边距 */
}


a {
    text-align: center; /* 将列表项内容居中 */
    text-decoration: none;
    color: #000;
    text-transform: capitalize;
}


.ellipsis-text {
    color: #8f9595;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-text-2 {
    color: #8f9595;
    font-size: 0.8rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 限制显示的行数为2行 */
    -webkit-box-orient: vertical;
    white-space: normal;
}

.grid4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
}

.mtop {
    margin-top: 50px;
}

.box {
    box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
    border-radius: 6px;
    text-align: center;
    padding: 30px;
    cursor: pointer;
}

.ant-card {
    .ant-card-body {
        padding: 10px;
        border-radius: 0 0 8px 8px;
    }
}


.word-break {
    white-space: normal;
    word-break: break-word;
}





