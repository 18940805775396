.heading {
  text-align: center;
  width: 60%;
  margin: auto;
}

.tool-bar {
  background-color: #545151;
  text-align: center;
  .scale-display{
    padding: 2px 8px;
    color: white;
    background-color: #2e2e2e;
  }
}


.pdf-container {
  display: flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: start;
  background-color: #6e6c6c;
  width: 100%;
  min-height: 600px;
  height: 100%;
}


