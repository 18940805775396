
.quickfiling-profile {

  background-color: white;
  padding: 40px 0;
  border-radius: 10px;
  position: sticky;
  top: 80px;
}



.organize-research {
  padding: 20px;

  .category-container {
    background-color: #ebeff7;
    border-radius: 8px;
    padding: 10px 5px;
    min-height: 840px;

    .title{
      padding: 5px;
    }

  }
}


.check-missing-container {

  margin: 20px;
  .title{
    border-radius: 10px;
    background-color: #e7e0e0;
    padding: 16px;
    margin-bottom: 16px;
    h5 {
      margin-bottom: 0;
    }
  }

}


.review-citation {
  margin: 20px;
}




.barStyle  {
  width: 10%;
  height: 100%;
  background-color: #484342;
  border-radius: 5px;
  position: absolute;
  animation: moveLeftRight 4s infinite ease-in-out;
};


.movingBarStyle  {
  width: 100%;
  height: 10px;
  background-color: #dcd5d5;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
};


.progressStyle {
  background-color: #484342;
  height: 10px;
  border-radius: 5px;
};




@keyframes moveLeftRight {
  0% {
    left: 0;
    width: 10%; /* 起始时宽度较短 */
  }
  25% {
    width: 100%; /* 在移动时变长 */
  }
  50% {
    left: 90%; /* 移动到容器右侧，宽度最大 */
    width: 10%; /* 到达右侧时变短 */
  }
  75% {
    width: 100%; /* 回程时再次变长 */
  }
  100% {
    left: 0;
    width: 10%; /* 回到起点时变短 */
  }
}