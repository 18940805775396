.header {
    background-color: #fff;
    height: 7vh;
    box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
    /*padding: 5px 0;*/
    position: sticky;
    top: 0;
    z-index: 99999;
    width: 100vw;
}

.header .menu-item {
    font-size: 20px;
}


.header a {
    transition: 0.5s;
    font-weight: 500;
}
.header a:hover {
    color: #27ae60;
}


.home-header {
    background-color: #00bf63;
    padding: 20px 250px;
    .ant-space-gap-col-large {
        column-gap: 50px;
    }

    a:hover{
        color: white;
        text-decoration: underline;
    }

    .home-logo {
        color: white;
        font-size: 35px;
    }

    .home-logo-line {
        width: 4px;
        height: 40.25px;
        background-color: black;
        margin-right: 10px;
    }


}

.sticky-head {
    position: sticky;
    top: 0;
    z-index: 1;
}
