.profile-form-container {
    max-width: 600px;
    margin-bottom: 50px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}




