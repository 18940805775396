.community {
    background-color: #F5F4EF;
}


.post-card{
    margin-bottom: 0.8rem;
    .ant-card-body {
        padding-bottom: 0.2rem;
    }
}