.editor-container {
  max-width: 65%;
  margin: 20px auto 0 auto;

  input {
    background-color: #f7f6f4;
    border-radius: 20px;
    height: 45px;
    font-size: 18px;
    margin-bottom: 20px;
  }
}

#toolbar {
  display: flex;
  align-items: center;
  background-color: #f7f6f4;
  padding: 8px 12px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ql-toolbar {

  button {
    cursor: pointer;
    margin-right: 10px;
  }
  button:hover,
  select:hover {
    background-color: #eee;
  }

  .ql-active {
    color: #1e90ff;
  }

}


.ql-editor {
  min-height: 500px;
  max-height: 500px;
  padding: 10px;
  background-color: #f7f6f4;
  border-radius: 20px;
  img {
    max-width: 100%; /* 限制图片的最大宽度为容器的宽度 */
    height: auto;    /* 保持图片的高度自动调整 */
  }
}

.ql-container.ql-snow {
  border: none;
}