
.chat-header {
  width: 100%;
  position: fixed;
  background: #fff;
  top: 0;
  z-index: 2;
  padding: 10px 20px;
  display: flex;
  justify-content: start;
}


.chat-content {
  width: 45%;
  margin-top: 100px;
  padding: 10px 10px 200px 10px;

  .chat-item {
    padding: 5px;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 10px;

  }
}

.chat-input {
  width: 50%;
  position: fixed;
  background: #fff;
  bottom: 0;
  padding: 10px 0 50px 0;


  .chat-input-item {
    width: 100%;
    background-color: #f2f2f2;
    border: 3px solid #f0f0f0;
    border-radius: 30px;
    padding: 5px;
    height: auto;

  }
}

.markdown-content {
  font-size: 16px; // 字体大小
  line-height: 1.8; // 行间距
  color: #333; // 字体颜色

  h1, h2, h3 {
    color: #45b845; // 标题颜色
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px; // 字体大小
    margin-bottom: 16px; // 段落间距
  }

  ul {
    margin-left: 20px; // 列表缩进
    list-style-type: disc; // 列表样式
  }

  li {
    margin-bottom: 8px; // 列表项间距
  }

  blockquote {
    border-left: 4px solid #ddd;
    padding-left: 10px;
    margin: 16px 0;
    color: #666;
    font-style: italic;
  }

  a {
    color: #1890ff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%; // 图片自适应宽度
    height: auto;
    border-radius: 5px;
    margin: 10px 0;
  }

  pre {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
    font-size: 14px;
    line-height: 1.6;
  }

  code {
    background: #f5f5f5;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 14px;
  }
}








